import React from "react"
import "./social.scss"
import map from "../images/map.svg"
import phone from "../images/phone.svg"
import instagram from "../images/instagram.svg"
import facebook from "../images/facebook.svg"
import email from "../images/email.svg"
import houzz from "../images/houzz.png"
import wfcp from "../images/wfcp.png"
import trustedsite from "../images/trustedsite.png"

const Social = () => (
  <div className="store-info-wrapper">
    <ul className="social">
      <li className="social-link">
        <a
          className="link"
          href="https://www.google.com/maps/place/8861+Camfield+Wy,+Frisco,+TX+75034/@33.1570376,-96.80747,17z/data=!3m1!4b1!4m6!3m5!1s0x864c3c25d9eb2cf1:0x2ae25718f69f8131!8m2!3d33.1570331!4d-96.8048951!16s%2Fg%2F11c0y67g_b?entry=ttu"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="image" src={map} alt="Map" />
        </a>
      </li>
      <li className="social-link">
        <a className="link" href="tel:2146181645">
          <img className="image" src={phone} alt="Phone" />
        </a>
      </li>
      <li className="social-link">
        <a
          className="link"
          href="https://www.instagram.com/berkeleydecor/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="image" src={instagram} alt="Instagram" />
        </a>
      </li>
      <li className="social-link">
        <a
          className="link"
          href="https://www.facebook.com/berkeleydecor1/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="image" src={facebook} alt="Facebook" />
        </a>
      </li>
      <li className="social-link">
        <a
          className="link"
          href="mailto:berkeleydecor@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="image" src={email} alt="Email" />
        </a>
      </li>
      <li className="social-link">
        <a
          className="link"
          href="https://www.houzz.com/best-of-houzz-2018/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="image" src={houzz} alt="Houzz" />
        </a>
      </li>
      <li className="social-link">
        <a
          className="link"
          href="https://www.wf-vision.com/education/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="image" src={wfcp} alt="WFCP" />
        </a>
      </li>
      <li className="social-link">
        <a
          className="link"
          href="https://www.trustedsite.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="image" src={trustedsite} alt="Trustedsite" />
        </a>
      </li>
    </ul>
  </div>
)

export default Social
