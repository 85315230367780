import React from "react"
import "./store-info.scss"
import Social from './social'

const StoreInfo = () => (
  <>
    <div className="store-info">
      <p className="tagline">Let's Create Your Perfect Home</p>
      <ul className="store-hours">
        <li>By Appointment Only</li>
        <li className="hours">Monday - Friday 10:00 AM - 12 PM and 1 PM - 5 PM</li>
        <span className="divider"></span>
        <li className="hours">Saturday & Sunday Closed</li>
        {/* <span className="divider"></span>
        <li className="hours">Sun Closed</li> */}
      </ul>
      <ul className="links">
        <li className="store-link">
          <a
            className="link"
            href="https://www.google.com/maps/place/8861+Camfield+Wy,+Frisco,+TX+75034/@33.1570376,-96.80747,17z/data=!3m1!4b1!4m6!3m5!1s0x864c3c25d9eb2cf1:0x2ae25718f69f8131!8m2!3d33.1570331!4d-96.8048951!16s%2Fg%2F11c0y67g_b?entry=ttu"
            target="_blank"
            rel="noopener noreferrer"
          >
            Location
          </a>
        </li>
        <span className="divider"></span>
        <li className="store-link">
          <a
            className="link"
            href="tel:2146181645"
          >
            214-618-1645
          </a>
        </li>
        <span className="divider"></span>
        <li className="store-link">
          <a
            className="link"
            href="mailto:berkeleydecor@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            berkeleydecor@gmail.com
          </a>
        </li>
      </ul>
      <Social />
      <p className="store-since">In Business Since 2007</p>
    </div>
  </>
)

export default StoreInfo
