import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import "./footer.scss"
import Social from './social'

const Footer = () => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery1 {
      site {
        siteMetadata {
          title
          tagline
        }
      }
    }
  `)

  return (
    <div className="footer">
      <Social />
      <div className='call-div'>
        <a className="call" href="tel:2146181645">
          214-618-1645
        </a>
      </div>
      <Link
        to="/"
        style={{
          color: "white",
          fontSize: "1rem",
          padding: `0.1rem`,
          textAlign: "center",
          textDecoration: "none",
        }}
      >
        <div>
          {data.site.siteMetadata.title} © {new Date().getFullYear()}
        </div>
      </Link>
    </div>
  )
}

export default Footer
