import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import berkeleyW from "../images/berkeley-white.svg"
import "./header.scss"
import barsW from "../images/barsW.svg"
import { Helmet } from "react-helmet"

const Header = ({ siteTitle }) => (
  <div className="header">
    <div className="header-wrapper">
      <div className="logo">
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
            display: `flex`,
            alignItems: "center",
            justifyItems: "center",
          }}
        >
          <img
            className="logo-image"
            src={berkeleyW}
            alt="Berkeley Decor Logo"
          />
          <div className='logo-text'>
            <p className="logo-text-top">{siteTitle}</p>
            <p className="logo-text-bottom">custom home decor</p>
          </div>
        </Link>
      </div>
      
      <div className="hamburger">
        <a href="#" className="menu-text">
          <img className="image" src={barsW} alt="Hamburger menu" />
          <p>menu</p>
        </a>
      </div>
    </div>
    <Helmet>
      <script
        type="text/javascript"
        src="https://cdn.ywxi.net/js/1.js"
        async
      ></script>
    </Helmet>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
